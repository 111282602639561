body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 18px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  
}

h1, h2, h3, h4 {
  font-weight: bold;
}

h1 {
  font-size: 26px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Prevent zoom into forms on mobile */
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type=file] {
  width: 100%;
}
