@import "./colors.css";

.linkerCard {
  
  border-radius: 5px;
  text-decoration: none;
}



.linkerCard ul {
  list-style-type: disc;
  list-style: disc;
  padding-left: 10px;
}

.cardLink:hover {
  text-decoration: none;
}
