@import "./colors.css";


.fs40 {
  font-size: 40px;
  font-weight: bolder;
}

.fs28 {
  font-size: 28px;
  font-weight: bolder;
}

.fs22 {
  font-size: 22px;
  font-weight: bolder;
}

.fs20 {
  font-size: 20px;
  font-weight: bolder;
}

.fs18slim {
  font-size: 18px;
}

.bsh {
    color: black;
    background-color: var(--scc) !important;
    border: none;
    font-family: sans-serif; 
    font-weight: bold;
}

.header {
  z-index: 1000;
}