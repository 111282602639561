@import "../components/colors.css";

.Main {
  height: 100%!important;
  min-height: 100vh;
}

.Profile {
  
}

.Events {
  height: 100%;
  min-height: 95vh;
}

.landing {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  text-align: center;
}


.Main .links {
  padding-left: 20px;
  padding-right: 20px;
}

.profileForm {
  padding: 20px;
  
  height: 100%;
}

.profileForm form {
  height: 100%;
  
}

.profileForm .error-message {
  color: red;
}

.profileForm .profileRow {
  padding-bottom: 20px;  
}



