@import "./colors.css";


.BSButton {
  
  border-radius: 2px;
  
  text-align: center;
  text-decoration: none;  
}


.LoaderButton[disabled] {
  color: grey;
  background-color: var(--sccdeact);
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
}
